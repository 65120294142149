import * as React from 'react'
import sty from 'src/layouts/styles/index'
import NavBrand from 'src/components/Header/Navigation/NavBrand'
import NavToggler from 'src/components/Header/Navigation/NavToggler'
import Nav from 'src/components/Header/Navigation/Nav'


const Navigation = ({ navProps, ...props }) => {

  // Toggler
  const [showMobile, setMobile] = React.useState(false)
  const toggle = () => setMobile(!showMobile)
  const hideNav = () => setMobile(false)

  return (
    <>
      <sty.Box {...props} variant='outerNavigation' >
        <sty.Flex variant='navigation' sx={navProps}>
          <NavBrand onClick={hideNav}
                    height={navProps.height} />
          <NavToggler onClick={toggle}
                      showMobile={showMobile}
                      size={navProps.height[0]} />
          <Nav onClick={hideNav}
               showMobile={showMobile}
               top={navProps.totalHeight} />
        </sty.Flex>
      </sty.Box>
    </>
  )
}

export default Navigation

import React from "react"
import { animated, useSpring } from "react-spring"

// import styled from "@emotion/styled"
import {
  Text as Text_,
  Heading as Heading_,
  Flex as Flex_,
  Box as Box_,
  Button as Button_,
  Link as Link_,
  Image as Image_,
} from "rebass"
import {
  Link as GatsbyLink,
  Image as GatsbyImage,
} from './RebassGatsby'
import {
  Label as Label_,
  Input as Input_,
  Textarea as Textarea_,
} from '@rebass/forms'

import { hex2rgba } from 'src/layouts/styles/helper'
import theme from "src/layouts/styles/theme"


// ---
export const Text = props => <Text_ variant='body' as='p' {...props} />
export const Display = props => <Text variant='display' {...props} />

const Heading = props => <Heading_ variant='heading' color='primary' {...props} />
export const Heading1 = props => <Heading variant='h1' as='h1' {...props} />
export const Heading2 = props => <Heading variant='h2' as='h2' {...props} />
export const Heading3 = props => <Heading variant='h3' as='h3' {...props} />


// ---
export const Box = React.forwardRef((props, ref) =>
  <Box_ ref={ref} variant='container' {...props} />)
export const Flex = React.forwardRef((props, ref) =>
  <Flex_ ref={ref} variant='container' {...props} />)

// ---
export const Button = React.forwardRef((props, ref) =>
  <Button_ ref={ref} color='primary' bg='secondary' {...props} />)


// -- https://reach.tech/router/api/Link
export const Link = React.forwardRef(({ simple=false, to, variant='link', ...props }, ref) => {
  if (simple) {
    return <Link_ href={to} ref={ref} variant={variant} {...props} />
  }
  return <GatsbyLink to={to} ref={ref} variant={variant} {...props} />
})
export const LinkButton = React.forwardRef(({ colors=false, isFilled=true, childElementObj=Link, ...props }, ref) => {

  const AnimatedElement = animated(childElementObj)

  colors = colors ? colors
    : {
      border: theme.colors.brand, // 'brand',
      background: hex2rgba(theme.colors.brand, 0), // 'transparent',
      text: theme.colors.brand, // 'brand',
      fade: theme.colors.secondary, // 'secondary',
    }

  const { border, background, text, fade } = colors
  const sx = {
    borderColor: border,
    // color: text,
  }

  // Hover State
  const [isHover, setHover] = React.useState(false)

  // Spring
  const { backgroundColor, color } = useSpring({
    // backgroundColor: isHover ? fade : background,
    backgroundColor: isHover ? (isFilled ? text : fade) : background,
    color: isHover ? (isFilled ? fade : text) : text,
    config: { tension: 200, friction: 11, clamp: true },
  })
  const style = {
    backgroundColor,
    color,
  }

  return (
    <Flex>
      <AnimatedElement
        ref={ref}
        variant='linkButton'
        style={style}
        sx={sx}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        {...props} />
    </Flex>
  )
})


// --
export const List = React.forwardRef((props, ref) =>
  <Flex ref={ref} as='ul' variant='list' {...props} />)
export const ListItem = React.forwardRef((props, ref) =>
  <Flex ref={ref} as='li' variant='listItem' {...props} />)


// --
export const Image = ({ simple=false, src, variant='image', draggable=false, ...props }) => {
  if (simple) {
    return <Image_ variant={variant} src={src} draggable={draggable} {...props} />
  }
  return <GatsbyImage variant={variant} fluid={src.childImageSharp.fluid} draggable={draggable} {...props} />
}

// --
export const Label = React.forwardRef((props, ref) =>
  <Label_ ref={ref} variant='label' {...props} />)
export const Input = React.forwardRef((props, ref) =>
  <Input_ ref={ref} variant='input' {...props} />)
export const Textarea = React.forwardRef((props, ref) =>
  <Textarea_ ref={ref} variant='textarea' {...props} />)


export default {
  Text, Display, Heading1, Heading2, Heading3,
  Flex, Box,
  Button,
  Link, LinkButton,
  List, ListItem,
  Image,
  Label, Input, Textarea,
}

/** Almost the same as from React. Please refer to
 * https://github.com/rebassjs/rebass/blob/master/packages/reflexbox/src/index.js
 *
 * Why?
 * Use full potential of Gatsby links with styling like Rebass.
 */

import styled from '@emotion/styled'
import {
  compose,
  space,
  layout,
  typography,
  color,
  flexbox,
} from 'styled-system'
import css, { get } from '@styled-system/css'
import { Link as GatsbyLink } from "gatsby"
import GatsbyImage from 'gatsby-image'


const sx = props => css(props.sx)(props.theme)
const base = props => css(props.__css)(props.theme)
const variant = ({
                   theme,
                   variant,
                   tx = 'variants',
                 }) =>
  css(
    get(theme, tx + '.' + variant,
      get(theme, variant)
    )
  )(theme)


export const Link = styled(GatsbyLink)(
  base,
  variant,
  sx,
  props => props.css,
  compose(
    space,
    layout,
    typography,
    color,
    flexbox,
  ),
)

export const Image = styled(GatsbyImage)(
  base,
  variant,
  sx,
  props => props.css,
  compose(
    space,
    layout,
    typography,
    color,
    flexbox,
  ),
)

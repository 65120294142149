import * as React from 'react'
import sty from 'src/layouts/styles/index'
import { LeftRight } from './index'


export const ContentImage = ({ src, ...props }) => {

  const sxRatio = {
    position: 'relative',
    // width: '100%',
    paddingTop: ['66.66%', null, '32.33%'], // 3:2 Aspect Ratio
  }
  const sxImage = {
    position: 'absolute',
    left: 0, right: 0, top: 0, bottom: 0,
    overflow: 'hidden',
    borderRadius: 'small',
  }

  return (
    <>
      <LeftRight sx={sxRatio}>
        <sty.Flex sx={sxImage}>
          <sty.Image src={src} width='100%' height='100%' {...props} />
        </sty.Flex>
      </LeftRight>
    </>
  )
}

export default ContentImage

import * as React from 'react'
import sty from 'src/layouts/styles/index'
import useImage from 'src/hooks/useImage'
import { HeaderElement } from '../index'
import { animated, useSpring } from "react-spring"


const IndexPage = () => {

  const { indexAI, indexAlpisol, indexEinblasen } = useImage()
  const SVGImage = (props) => <sty.Image simple {...props} />
  const AnimatedSVG = animated(SVGImage)

  // Styles
  const sx = {
    position: 'relative',
    height: 'max(300px, 45vh)',
  }
  const sxAI = {
    position: 'absolute',
    height: '95%',
    maxWidth: '200%',
    opacity: '10%',
  }
  const sxWrapper = {
    position: 'absolute',
    flexDirection: 'column',
    height: '50%',
  }
  const sxText = {
    width: 'max(200px, 30vh)',
    marginTop: 'max(20px, 3vh)',
  }

  // Spring
  const [{ scrollY }, setY] = useSpring(() => ({ scrollY: 0 }))
  const handleScroll = () => setY({ scrollY: window.scrollY })
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });
  const styleAI = { transform: scrollY.interpolate(x => `translateY(clamp(-200%, ${x}px, 200%))`) }
  const styleAlpisol   = { transform: scrollY.interpolate(x => `translateX(clamp(-200%, ${x}px, 200%))`) }
  const styleEinblasen = { transform: scrollY.interpolate(x => `translateX(clamp(-200%, ${-x}px, 200%))`) }

  return (
    <>
      <HeaderElement sx={sx}>
        <AnimatedSVG src={indexAI} sx={sxAI} style={styleAI} />
        <sty.Flex sx={sxWrapper}>
          <AnimatedSVG src={indexAlpisol}   sx={sxText} style={styleAlpisol} />
          <AnimatedSVG src={indexEinblasen} sx={sxText} style={styleEinblasen} />
        </sty.Flex>
      </HeaderElement>
    </>
  )
}

export default IndexPage

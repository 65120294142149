import { useStaticQuery, graphql } from 'gatsby'


export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query siteMetadataQuery {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          calling
          mailing
          menuItems {
            name
            link
            isFilled
          }
          footerItems {
            name
            link
          }
        }
      }
    }
  `)

  const {
    site: {
      siteMetadata: {
        title, description, author, siteUrl, calling, mailing,
        menuItems, footerItems,
      },
    },
  } = data

  return {
    title,
    description,
    author,
    siteUrl,
    calling,
    mailing,
    menuItems,
    footerItems,
  }
}

export default useSiteMetadata

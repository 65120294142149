import * as React from 'react'
import sty from 'src/layouts/styles/index'
import { ContentImage } from 'src/components/Main/ContentBox/ContentImage'
import { ContentText } from 'src/components/Main/ContentBox/ContentText'


export const LeftRight = props => <sty.Box variant='leftRightElement' {...props} />


export const ContentBox = ({ inverse=false, src=false, title, subtitle, text, textAs=null, button={} }) => {

  const sx = {  // had problems to use that as variant in theme
    userSelect: 'text',
    flexDirection: ['column', null, inverse ? 'row-reverse' : 'row'],
    justifyContent: src ? 'space-between' : 'center',
    my: [6, 8, 8],  // same as in 'kontakt' page, in <Embed/>
  }

  const textProps = { title, subtitle, text, textAs, button, h1: !src }

  return (
    <>
      <sty.Flex variant='layoutWrapper' sx={sx}>
        { src && <ContentImage src={src} /> }
        { src && <sty.Box width='3%' /> }
        <ContentText {...textProps} />
      </sty.Flex>
    </>
  )
}

export default ContentBox

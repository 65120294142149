import * as React from 'react'
import sty from 'src/layouts/styles/index'
import SEO from 'src/components/SEO'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'


export const LayoutWrapper = ({ children, ...props }) => (
  <>
    <sty.Box variant='containerSafe' bg='primary' {...props}>
      <sty.Box variant='layoutWrapper'>
        { children }
      </sty.Box>
    </sty.Box>
  </>
)


// Layout
const Layout = ({ children, seo={}, index=false }) => {

  return (
    <>
      <SEO {...seo} />

      <Header index={index} />

      <sty.Box as='main' variant='containerSafe'>
        { children }
      </sty.Box>

      <Footer />
    </>
  )
}


export default Layout

import * as React from 'react'
import sty from 'src/layouts/styles/index'
import { animated, useSpring, interpolate } from 'react-spring'


const NavToggler = ({ showMobile, size, ...props }) => {

  const AnimatedFlex = animated(sty.Flex)

  // Props
  const sliceSX = { height: size / 16, my: size / 12 }
  const sliceOffY = sliceSX.height + 2 * sliceSX.my

  // -- ShowMobile
  const burgerSpring = useSpring({
    x: showMobile ? 1 : 0,
    config: { tension: 100, friction: 18 }
  })
  const styleTop = {
    transform: interpolate([
      burgerSpring.x.interpolate({ range: [0, .7, 1], output: [0, 1, 1] }).interpolate(x => x * sliceOffY),
      burgerSpring.x.interpolate({ range: [0, .7, 1], output: [0, -.1, -1] }).interpolate(x => x * 45),
      burgerSpring.x.interpolate({ range: [0, .7, 1], output: [1, .9, .75] }),
    ], (t, r, s) => `translate(0, ${t}px) rotate(${r}deg) scaleX(${s})`)
  }
  const styleMiddle = {
    transform: burgerSpring.x.interpolate({ range: [0, .3, 1], output: [1, 0, 0] })
      .interpolate(x => `scaleX(${x})`)
  }
  const styleBottom = {
    transform: interpolate([
      burgerSpring.x.interpolate({ range: [0, .7, 1], output: [0, -1, -1] }).interpolate(x => x * sliceOffY),
      burgerSpring.x.interpolate({ range: [0, .7, 1], output: [0, .1, 1] }).interpolate(x => x * 45),
      burgerSpring.x.interpolate({ range: [0, .7, 1], output: [1, .9, .75] }),
    ], (t, r, s) => `translate(0, ${t}px) rotate(${r}deg) scaleX(${s})`)
  }

  return (
    <sty.Flex variant='navToggler'
              sx={{ height: size, width: size }}
              {...props}>
      <AnimatedFlex variant='navTogglerSlice'
                    style={styleTop} sx={sliceSX} />
      <AnimatedFlex variant='navTogglerSlice'
                    style={styleMiddle} sx={sliceSX} />
      <AnimatedFlex variant='navTogglerSlice'
                    style={styleBottom} sx={sliceSX} />
    </sty.Flex>
  )
}

export default NavToggler

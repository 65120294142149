import * as React from 'react'
import { animated, useSpring } from "react-spring"

import sty from 'src/layouts/styles/index'
import useSiteMetadata from 'src/hooks/useSiteMetadata'
import useImage from 'src/hooks/useImage'


const NavBrand = (props) => {

  const AnimatedImage = animated(sty.Image)

  // Props
  const sxLink = { position: 'relative', justifyContent: 'flex-start', width: 'auto', zIndex: 1 }  // height in 'props'
  const sxRect = { position: 'absolute', left: 0, height: '100%', zIndex: -1 }
  const sxBrand = { height: '100%' }

  // Hooks
  const { title } = useSiteMetadata()
  const { brandImg, brandRect } = useImage()

  // Spring
  const [isHover, setHover] = React.useState(false)
  const spring = useSpring( {
    reset: true, from: { x: 0 },
    x: isHover ? 1 : 0,
    config: { mass: 20, tension: 500, friction: 90 },
  })
  const style = {
    transform: spring.x.interpolate({ range: [0, .5, 1], output: [1, 1.1, 1] })
      .interpolate(s => `scale(${s})`)
  }

  return (
    <>
      <sty.Link to='/' variant='text.heading' title={title}
                sx={sxLink}
                {...props}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
        <AnimatedImage simple src={brandRect} sx={sxRect} style={style} />
        <sty.Image simple src={brandImg} sx={sxBrand} />
      </sty.Link>
    </>
  )
}

export default NavBrand

import { useStaticQuery, graphql } from 'gatsby'

import brandImgBlack from 'src/images/brand/alpisol-brand-black.svg'
import brandImg from 'src/images/brand/alpisol-brand.svg'
import brandRect from 'src/images/brand/alpisol-rectangle.svg'
import indexAI from 'src/images/index-page/ai.svg'
import indexAlpisol from 'src/images/index-page/alpisol.svg'
import indexEinblasen from 'src/images/index-page/einblasen.svg'


export const useImage = () => {
  const data = useStaticQuery(graphql`
    query imageQuery {
      alpsteinView: file(relativePath: { eq: "impressions/alpstein-view.jpeg" }) {
        childImageSharp { fluid(maxWidth: 4032) {...GatsbyImageSharpFluid} }
      }
      family: file(relativePath: { eq: "impressions/family.jpg" }) {
        childImageSharp { fluid(maxWidth: 1918) {...GatsbyImageSharpFluid} }
      }
      productEps: file(relativePath: { eq: "products/prod-eps.jpeg" }) {
        childImageSharp { fluid(maxWidth: 4032) {...GatsbyImageSharpFluid} }
      }
      productPaper: file(relativePath: { eq: "products/prod-paper.jpeg" }) {
        childImageSharp { fluid(maxWidth: 3537) {...GatsbyImageSharpFluid} }
      }
      productStone: file(relativePath: { eq: "products/prod-stone.jpeg" }) {
        childImageSharp { fluid(maxWidth: 3946) {...GatsbyImageSharpFluid} }
      }
      siteNew: file(relativePath: { eq: "site/new-build.jpeg" }) {
        childImageSharp { fluid(maxWidth: 3024) {...GatsbyImageSharpFluid} }
      }
      siteRenov: file(relativePath: { eq: "site/renovation.jpeg" }) {
        childImageSharp { fluid(maxWidth: 840) {...GatsbyImageSharpFluid} }
      }
      siteRoof: file(relativePath: { eq: "site/roof.jpg" }) {
        childImageSharp { fluid(maxWidth: 1811) {...GatsbyImageSharpFluid} }
      }
      siteSaentis: file(relativePath: { eq: "site/saentis.jpg" }) {
        childImageSharp { fluid(maxWidth: 1920) {...GatsbyImageSharpFluid} }
      }
    }
  `)

  return {
    ...data,
    // *.svg files
    brandImgBlack, brandImg, brandRect,
    indexAI, indexAlpisol, indexEinblasen,
  }
}

export default useImage

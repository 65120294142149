import * as React from 'react'
import sty from 'src/layouts/styles/index'
import { useSiteMetadata } from 'src/hooks/useSiteMetadata'
import { LeftRight } from './index'


export const ContentText = ({ h1, title, subtitle, text, textAs, button }) => {

  const sxDisplay = {
    paddingTop: 3,
  }

  const sxText = {
    px: [0, 8],
    paddingTop: 6,
    paddingBottom: 6,
  }

  let { link, linkName } = button
  const { menuItems } = useSiteMetadata()
  const getLinkName = name => menuItems.filter(e => e.link === name).map(e => e.name)
  linkName = linkName ? linkName : getLinkName(link)

  return (
    <>
      <LeftRight>
        { title === ''    ? <></> : (
                              h1  ? <sty.Heading1   children={title} />
                                  : <sty.Heading2   children={title} />
        ) }
        { subtitle === '' ? <></> : <sty.Display    children={subtitle} sx={sxDisplay} /> }
        { !text           ? <></> : <sty.Text       children={text}     sx={sxText}    as={textAs} /> }
        { !link           ? <></> : <sty.LinkButton children={linkName} to={link} /> }
      </LeftRight>
    </>
  )
}

export default ContentText

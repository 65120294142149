import * as React from "react"
import sty from 'src/layouts/styles/index'
import useSiteMetadata from 'src/hooks/useSiteMetadata'
import { LayoutWrapper } from 'src/layouts/layout'


const FooterElement = (props) => <sty.Flex variant='containerFooter' {...props} />


const FooterBlock = (props) => (
  <sty.Flex variant='footerBlock'
            children={<sty.Text variant='footerText' {...props} />} />
)


const Disclaimer = (props) => (
  <sty.Flex variant='footerDisclaimer'
            children={<sty.Text variant='footerDisclaimer' {...props} />} />
)


export const PhoneLink = ({ tel='', ...props }) => <sty.Link simple to={`tel:${tel}`} children={tel} {...props} />


export const MailLink = ({ mailto='', ...props }) => <sty.Link simple to={`mailto:${mailto}`} children={mailto} {...props} />


const MenuItem = ({ name, link, isButton, ...props }) => {
  const linkProps = { bg: 'primary', color: 'secondary' }

  return (
    <>
      <sty.Link to={link} key={link} children={name} {...linkProps} {...props} />
    </>
  )
}


const Footer = () => {

  const { siteUrl, calling, mailing, footerItems } = useSiteMetadata()

  return (
    <>
      <LayoutWrapper as='footer'>

        <FooterElement>
          <FooterBlock>
            <strong>Alpisol Einblasen GmbH</strong><br />Schwarzeneggstrasse 13<br />9058 Brülisau
          </FooterBlock>
          <FooterBlock>
            <strong>Kontakt</strong><br /><PhoneLink tel={calling} /><br /><MailLink mailto={mailing} />
          </FooterBlock>
        </FooterElement>

        <FooterElement sx={{ flexDirection: 'column !important' }}>
          <FooterBlock>
            {footerItems.map(item => <MenuItem key={item.link.toString()} {...item} />).reduce((prev, curr) => [prev, ' | ', curr])}
          </FooterBlock>
          <Disclaimer>
            Copyright &copy; {(new Date().getFullYear())} Alpisol Einblasen GmbH
            {' | '}<sty.Link simple to={siteUrl}>{ siteUrl.replace(/^https?:\/\//i, "") }</sty.Link>
          </Disclaimer>
        </FooterElement>

      </LayoutWrapper>
    </>
  )
}

export default Footer

import * as React from 'react'
import ScrollLock from 'react-scrolllock'
import { animated, useSpring } from "react-spring"
import sty from 'src/layouts/styles/index'
import theme from 'src/layouts/styles/theme'
import { hex2rgba } from 'src/layouts/styles/helper'
import useSiteMetadata from 'src/hooks/useSiteMetadata'


const NavList = (props) => <sty.List variant='navList' {...props} />


const NavButton = ({ colors, isActive, isFilled, isHover, ...props }) => {

  const AnimatedFlex = animated(sty.Flex)

  const { border, background, text, fade } = colors
  const sx = {
    borderColor: ['transparent', null, border],
    color: text,
  }

  // Spring
  const isFaded = isActive || isHover
  const { backgroundColor } = useSpring({
    backgroundColor: isFaded ? fade : background,
    // backgroundColor: isFaded ? (isFilled ? text : fade) : background,
    // color: isFaded ? (isFilled ? fade : text) : text,
    config: { tension: 200, friction: 11, clamp: true },
  })
  const style = {
    backgroundColor,
  }

  return (
    <>
      <AnimatedFlex
        variant='navButton'
        style={style}
        sx={sx}
        {...props}>
        {props.children}
      </AnimatedFlex>
    </>
  )
}


const NavListItem = ({ name, link, isFilled, onClick, ...props }) => {

  // Colors
  const stdColors = {
    // border: 'transparent',
    background: hex2rgba(theme.colors.primary_2, 0), // 'transparent',
    text: theme.colors.secondary, // 'secondary',
    fade: theme.colors.primary_2, // 'primary_2',
  }
  const filledColors = {
    // border: theme.colors.brand, // 'brand',
    background: hex2rgba(theme.colors.brand, 0), // 'transparent',
    text: theme.colors.secondary, // 'brand',
    fade: theme.colors.brand, // 'secondary',
  }
  const colors = isFilled ? filledColors : stdColors

  // Hover State
  const [isHover, setHover] = React.useState(false)

  // Link State
  const isActive = typeof location !== `undefined` && (
    link === location.pathname.slice(0, -1) || link === location.pathname
  )

  return (
    <>
      <sty.ListItem variant='navListItem'>
        <sty.Link variant='navLink' to={link}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  onClick={onClick}>
          <NavButton colors={colors}
                     isActive={isActive}
                     isFilled={isFilled}
                     isHover={isHover}
                     {...props}
                     children={name} />
        </sty.Link>
      </sty.ListItem>
    </>
  )
}


const NavListItemLine = () => <sty.Flex variant='navListItemLine' />


const Nav = ({ showMobile, top, onClick, ...props }) => {

  const AnimatedFlex = animated(sty.Flex)

  // Props
  const sxToggler = {
    top: top,
    left: 0,
    right: 0,
    bottom: 0,
    bg: 'outOfFocus',
  }
  const sxNav = {
    left: 'auto', // 'min(30%, 200px)',
    right: 0,
    top: top,
    bottom: 0,
    width: ['clamp(200px, 70%, 500px)', null, 'auto'],
    // height: navHeight,
    borderTop: [`2px solid ${theme.colors.primary_2}`, null, 'none'],
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  }

  // Hooks
  const { menuItems } = useSiteMetadata()

  // Spring
  const { state } = useSpring({
    state: showMobile ? 1 : 0,
    config: {
      mass: 0.7,
      tension: 114,
      friction: 16,
      clamp: true
    },
  })
  const styleToggler = {
    display: state.interpolate(s => s === 0 ? 'none' : null),
    opacity: state.interpolate(s => s),
  }
  const styleNav = {
    transform: state.interpolate(s => `translate(${(1 - s) * 100}%)`),
    transformOrigin: 'top right',
    visibility: state.interpolate(s => (s === 0 ? 'hidden' : 'visible'))
  }

  return (
    <>
      <ScrollLock isActive={showMobile} accountForScrollbars={false} />
      {/* Invisible Button over whole area */}
      <AnimatedFlex variant='containerFixed'
                    sx={sxToggler}
                    style={styleToggler}
                    onClick={onClick} />
      {/* Navigation element */}
      <AnimatedFlex as='nav' variant='nav'
                    sx={sxNav}
                    style={styleNav}
                    {...props}>
        <NavList>
          {
            menuItems.map(item => <NavListItem key={item.link.toString()}
                                               onClick={onClick} {...item} />)
              .reduce((acc, x) => acc === null ? x : <>{acc} <NavListItemLine /> {x}</>, null)
          }
        </NavList>
      </AnimatedFlex>
    </>
  )
}

export default Nav

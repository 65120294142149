export function sumArrays(arr1, arr2) {
  return arr1.map((num, idx) => num + arr2[idx])
}


export function getScrollbarWidth() {
  const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;

  return scrollbarWidth;
}

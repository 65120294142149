import * as React from 'react'
import theme from 'src/layouts/styles/theme'
import sty from 'src/layouts/styles/index'
import IndexPage from 'src/components/Header/IndexPage'
import Navigation from 'src/components/Header/Navigation'
import { LayoutWrapper } from 'src/layouts/layout'
import { sumArrays } from 'src/components/Header/utils'


export const HeaderElement = (props) => <sty.Flex variant='containerHeader' {...props} />


const Header = ({ index=false }) => {
  // Props for Navigation
  const brandHeight = [40, 45]  // Height of the Logo
  const brandMargin = theme.variants.containerHeader.my  // upper + lower margin
  const totalHeight = sumArrays(brandHeight, brandMargin.map(i => 2 * theme.space[i]))
  const navProps = { height: brandHeight, my: brandMargin, totalHeight: totalHeight }


  return (
    <>
      <Navigation as='header' navProps={navProps} />
      { index && <LayoutWrapper children={<IndexPage />} /> }
      {/* typeof window !== `undefined` && window.location.pathname === '/'
      && <LayoutWrapper children={<IndexPage />} /> */}
    </>
  )
}

export default Header
